import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as queryString from 'query-string'
import DatoCmsSearch from 'datocms-search/dist/datocms-search.base'

import { siteUrl } from '../../config/site'
import styles from '../scss/pages/search.module.scss'

import { Container } from '../components/elements'
import Page from '../components/Page'
import SEO from '../components/SEO'
import Newsletter from '../components/Newsletter'

import SpinnerSVG from './../assets/svg/spinner.svg'
import { Link } from 'gatsby'

const SearchPage = ({ location, navigate }) => {
  const { q } = queryString.parse(location.search)
  const [query, setQuery] = useState(q || '')
  const [results, setResults] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { register, handleSubmit, getValues } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      search: query,
    },
  })

  useEffect(() => {
    if (query === '') return

    onSubmit({ search: query })
  }, [])

  const fetchResults = async (query, page = 1, currentResults = []) => {
    let limit = 10
    let offset = page > 1 ? page * 10 : 0

    const client = new DatoCmsSearch(
      process.env.GATSBY_DATO_SEARCH_TOKEN,
      'production'
    )

    try {
      const res = await client.search(query, { limit, offset })
      const searchResults = res.results
      const resultsSet = new Set() // Create unique array of urls (without trailing slash)
      searchResults.map(({ url }) => {
        resultsSet.add(url.replace(/\/$/, ''))
      })
      const filteredResults = searchResults.filter(({ url }) => {
        return Array.from(resultsSet).includes(url)
      })

      setResults([...currentResults, ...filteredResults])
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    setQuery(data.search)
    navigate(`/search?q=${data.search}`)

    await fetchResults(data.search)

    setIsSubmitting(false)
  }

  const loadMoreResults = async () => {
    setIsSubmitting(true)

    const newPage = currentPage + 1

    await fetchResults(getValues('search'), newPage, results)

    setCurrentPage(newPage)

    setIsSubmitting(false)
  }

  return (
    <Page>
      <SEO title="Search - Erudus, the company united around food data" />

      <section className="c-section c-section--pageHeader">
        <div className="c-section__outer container">
          <div className="c-section__inner pb-0">
            <div className="c-pageHeader mb-3">
              <span className="c-pageHeader__title">Search</span>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.searchBoxSection}>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className={styles.input}
              ref={register}
              type="text"
              aria-label="Search"
              placeholder="Search e.g. Allergies"
              id="search"
              name="search"
              required
              autoComplete="off"
            />
            <div className="text-right">
              <button
                type="submit"
                className="c-button c-button--secondary"
                disabled={isSubmitting}
              >
                Search
              </button>
            </div>
          </form>
        </Container>
      </section>

      <section className={styles.resultsSection}>
        {results.length > 0 && (
          <Container>
            <div className={styles.results}>
              {results.map((result) => (
                <Link
                  key={`search_result_${result.url}`}
                  className={styles.resultLink}
                  to={result.url.replace(siteUrl, '')}
                >
                  <div className={styles.resultItem}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: result.title,
                      }}
                    />
                  </div>
                </Link>
              ))}
            </div>
            <div className="mt-4 text-center">
              {!isSubmitting && (
                <button
                  type="submit"
                  className="c-button c-button--secondary"
                  onClick={() => loadMoreResults()}
                >
                  Load more
                </button>
              )}
            </div>
          </Container>
        )}
        {isSubmitting && (
          <div className="mt-4 text-center">
            <SpinnerSVG />
          </div>
        )}
      </section>

      <Newsletter />
    </Page>
  )
}

export default SearchPage
